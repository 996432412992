import SearchIcon from 'components/icon/search-icon';
import SquareWrapper, { SquareIconProps } from '../wrapper';
type Props = SquareIconProps & {className?: string};

export default function SquareSearchIcon ({ onClick, className, size, testId, isHoverable, bgColor = 'dark' }: Props) {
  return (
    <SquareWrapper onClick={onClick} size={size} isHoverable={isHoverable} bgColor={bgColor}>
      <SearchIcon className={className} testId={testId}/>
    </SquareWrapper>
  );
}