import React, { useState, useEffect, ReactNode, useContext } from 'react';
import { useRouter } from 'next/router';
import ZooHeader from 'components/header/zoo';
import ExpHeader from 'components/header/exp';
import CommercialHeader from 'components/header/commercial';
import { trackCurrentPageView, trackDelayedPageView } from 'utils/google-tag-manager';
import { buildClassName } from 'utils/build-class-name';
import LoadingSpinner from 'components/loading-spinner';
import { FeaturesContext, IFeatures } from 'contexts/features';
import MobileBottomNav from 'components/header/zoo/mobile-bottom-nav';
import { AreaListingsRouteMatchObject, generateRouteMatchObjectFromPath, getRouteNameFromPath, LISTING_ROUTE, AREA_LISTINGS_ROUTE } from 'components/dynamic-page/route-matchers';
import { usePreferencesContext } from 'contexts/preferences';
import deepmerge from 'deepmerge';
import { useThemeContext, useUser } from 'contexts';
import dynamic from 'next/dynamic';
import { ThemeNames } from 'types/themes';
import styles from './style.module.scss';

import type { Filter } from 'contexts/preferences/listing-params/types';

interface Props {
  children: ReactNode;
  className?: string;
}

const useRouterLoading = () => {
  const router = useRouter();
  const { setHasNavigatedFromInternalLink } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const routeChangeStartHandler = () => {
      setHasNavigatedFromInternalLink(true);
      setIsLoading(true);
    };
    const routeChangeCompleteHandler = () => setIsLoading(false);

    /**
     * Due to rapid reloading in dev routeChangeComplete may never be called
     * Prevent the loading spinner from staying on the screen forever
     */
    const routeChangeError = (error: any) => {
      if (error.message === 'Cancel rendering route') setIsLoading(false);
    };

    router.events.on('routeChangeStart', routeChangeStartHandler);
    router.events.on('routeChangeComplete', routeChangeCompleteHandler);

    if (process.env.NODE_ENV !== 'production') {
      router.events.on('routeChangeError', routeChangeError);
    }

    return () => {
      router.events.off('routeChangeStart', routeChangeStartHandler);
      router.events.off('routeChangeComplete', routeChangeCompleteHandler);

      if (process.env.NODE_ENV !== 'production') {
        router.events.off('routeChangeError', routeChangeError);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading;
};

const mobileNavBlackList = [
  '/home-appraisal/property-details/results', '/search', '/explore-exp', '/referrals', '/my-link-my-lead',
  '/sell', '/buy', '/agents-search', '/join-exp', '/exp-specialized-divisions', '/experts'];

export default function Layout({ children, className = '' }: Props) {
  const isLoading = useRouterLoading();
  const { features, areDelayedScriptsLoaded, isWebView, isMobile } = useContext(FeaturesContext) as IFeatures;
  const { listingParams } = usePreferencesContext();
  const { themeName } = useThemeContext();
  const router = useRouter();
  const [ChatWidget, setChatWidget] = useState<any>();
  const [AppInstallBanner, setAppInstallBanner] = useState<any>();
  const cleanPath = router.asPath.split('?')[0];
  const routeName = getRouteNameFromPath(cleanPath);
  const isOnAreaPage = routeName === AREA_LISTINGS_ROUTE;
  const isOnListingPage = routeName === LISTING_ROUTE;
  const isOnHomePage = router.route === '/';
  const pageName = router.asPath.replace(/\//g, '-').substring(1);
  const isBlogWidget = pageName.startsWith('team-');
  const showChatWidget = features.useSmartChat && !isMobile && !isBlogWidget && !isWebView;
  const isZoocasaTenant = themeName === ThemeNames.ZOOCASA;
  const showMobileBottomNav = !isOnListingPage && !mobileNavBlackList.includes(router.route) && (!isZoocasaTenant && !isOnHomePage);

  useEffect(() => {
    if (showChatWidget && !ChatWidget) {
      setChatWidget(dynamic(import('components/chat-widget')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChatWidget]);

  useEffect(() => {
    if (areDelayedScriptsLoaded) {
      if (!AppInstallBanner && isZoocasaTenant) {
        setAppInstallBanner(dynamic(import('components/app-install-banner')));
      }
      trackDelayedPageView();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areDelayedScriptsLoaded]);

  useEffect(() => {
    trackCurrentPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOnAreaPage) {
      const routeMatchObject: AreaListingsRouteMatchObject = generateRouteMatchObjectFromPath(router.asPath, false);
      listingParams.setFilter(deepmerge(listingParams.filter, routeMatchObject.filter as Filter));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnAreaPage]);

  return (
    <>
      {themeName === ThemeNames.ZOOCASA && <ZooHeader />}
      {[ThemeNames.EXP_REALTY_CA, ThemeNames.EXP_REALTY_US].includes(themeName as ThemeNames) && <ExpHeader />}
      {themeName === ThemeNames.EXP_COMMERCIAL && <CommercialHeader />}
      {AppInstallBanner && <AppInstallBanner />}
      {isLoading && <div className={styles.loading}><LoadingSpinner /></div>}
      {!isLoading && <div className={buildClassName(styles.page, className)}>{children}</div>}
      {showChatWidget && ChatWidget && <ChatWidget />}
      <div className={styles['mobile-nav']}>{showMobileBottomNav && <MobileBottomNav />}</div>
    </>
  );
}
