import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const DropdownArrowIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="-2 0 14 4" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#181B1E" transform="translate(-87 -158)">
          <g transform="translate(38 138)">
            <path d="M 55.5 20 L 62 26 L 60 26 L 55.5 22 L 52 26 L 50 26 z" transform="rotate(-180 55.5 23)"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DropdownArrowIcon;
