import { useRef, useEffect } from 'react';

export default function useOutsideClickHandler<T extends HTMLElement>(callback: (() => void) | undefined) {
  const element = useRef<T>(null);

  useEffect(() => {
    document.body.addEventListener('click', handler, { passive: true, capture: true });
    return () => document.body.removeEventListener('click', handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, callback]);


  const handler = (event: MouseEvent) => {
    const clickedChatWidget = document.getElementById('chat-widget')?.contains(event.target as HTMLElement);
    if (!clickedChatWidget) {
      const clickedElement = event.target;
      const parentElement = element.current;
      const hasClickedSelf = clickedElement === parentElement;
      const hasClickedChild = parentElement && parentElement.contains(clickedElement as HTMLElement);
      const hasClickedOutside = !hasClickedSelf && !hasClickedChild && parentElement;
      if (hasClickedOutside && callback) {
        callback();
      }
    }
  };

  return element;
}
