import MenuIcon from 'components/icon/menu-icon';
import SquareWrapper, { SquareIconProps } from '../wrapper';
type Props = SquareIconProps & {className?: string};

export default function SquareMenuIcon ({ onClick, className, size, testId, isHoverable, bgColor = 'dark' }: Props) {
  return (
    <SquareWrapper onClick={onClick} size={size} isHoverable={isHoverable} bgColor={bgColor} testId={testId}>
      <MenuIcon className={className}/>
    </SquareWrapper>
  );
}