import storage from 'utils/storage';
import { HOME_APPRAISAL_DETAILS, HOME_APPRAISAL_PROPERTY } from 'constants/cookies';
import Listing from 'data/listing';

type HomeAppraisalPropertyListing = Pick<Listing,
  'id' |
  'streetName' |
  'unitNumber' |
  'bedrooms' |
  'bathrooms' |
  'styleName' |
  'parking' |
  'squareFootage' |
  'locker' |
  'addressSlug' |
  'city' |
  'province' |
  'postalCode' |
  'position'>;

type HomeAppraisalProperty = google.maps.GeocoderResult | HomeAppraisalPropertyListing;


class HomeAppraisalStorage {
  // Set the property in the storage from Search results
  static setProperty(data: HomeAppraisalProperty) {
    storage.set(HOME_APPRAISAL_PROPERTY, data);
  }

  // Get the property from the storage from the Property Details page
  static getProperty(): HomeAppraisalProperty | undefined {
    return storage.get(HOME_APPRAISAL_PROPERTY) as HomeAppraisalProperty;
  }

  // Get property details from the storage from Home Appraisal Results page
  static getDetails() {
    return storage.get(HOME_APPRAISAL_DETAILS);
  }

  // Set property details in the storage from the Property Details page
  static setDetails(values: Record<string, unknown>) {
    storage.set(HOME_APPRAISAL_DETAILS, {
      address: values.address,
      unitNumber: values.unitNumber,
      bedrooms: values.bedrooms,
      bathrooms: values.bathrooms,
      homeType: values.homeType,
      parking: values.parking,
      sqft: values.sqft,
      locker: !!values.locker,
    });
  }

  static clearProperty() {
    storage.delete(HOME_APPRAISAL_PROPERTY);
  }

  static clearDetails() {
    storage.delete(HOME_APPRAISAL_DETAILS);
  }
}

export default HomeAppraisalStorage;