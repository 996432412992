import { camelizeKeys } from '@zoocasa/node-kit/objects';
import { Filter } from 'contexts/preferences/listing-params';
import { ThemeName } from 'themes';
import { formatQueryIntoURLQueryParams } from 'utils/listing-query-helper';

export type SavedSearch = {
    id: string;
    imageUrl: string | null;
    listingsCount: number | null;
    description: string;
    frequency: number;
    createdAt: Date;
    updatedOn: Date;
    path: string;
    source: ThemeName;
    formParams: {
        filter: Filter;
    };
};

export class SavedSearchImpl implements SavedSearch {
  id: string;
  imageUrl: string;
  listingsCount: number;
  description: string;
  frequency: number;
  source: ThemeName;
  createdAt: Date;
  updatedOn: Date;
  formParams: { filter: Filter };
  
  constructor(savedSearch: Record<string, unknown>) {
    const camelizedSavedSearch = camelizeKeys(savedSearch);
    const attributes = camelizedSavedSearch.attributes as Record<string, unknown>;
    const relationships = camelizedSavedSearch.relationships as Record<
        string,
        unknown
      >;
    const formattedSavedSearch = {
      ...attributes,
      ...relationships,
      id: camelizedSavedSearch.id,
      createdAt: new Date(attributes.createdAt as string),
      updatedOn: new Date(attributes.updatedOn as string),
    } as SavedSearch;
    Object.assign(this, formattedSavedSearch);
  }
  
  
  get path() {
    return `/search?${formatQueryIntoURLQueryParams(this.formParams.filter)}`;
  }
}