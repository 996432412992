import React, { MouseEvent } from 'react';
import { testIds } from 'constants/test-constants';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const UserIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className={className} onClick={onClick} data-testid={testIds.userIcon}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" data-testid={testIds.mobileuser}>
        <path fill="#000" fillRule="nonzero" d="M26.503 20.072a15.465 15.465 0 00-5.9-3.7A8.972 8.972 0 0024.5 8.975C24.5 4.026 20.474 0 15.525 0S6.55 4.026 6.55 8.975a8.972 8.972 0 003.897 7.397 15.465 15.465 0 00-5.9 3.7A15.424 15.424 0 000 31.05h2.426c0-7.223 5.876-13.1 13.099-13.1s13.1 5.877 13.1 13.1h2.425c0-4.147-1.615-8.046-4.547-10.978zm-10.978-4.547a6.557 6.557 0 01-6.55-6.55 6.557 6.557 0 016.55-6.55 6.557 6.557 0 016.55 6.55 6.557 6.557 0 01-6.55 6.55z"></path>
      </g>
    </svg>
  );
};

export default UserIcon;
