import UserIcon from 'components/icon/user-icon';
import SquareWrapper, { SquareIconProps } from '../wrapper';
type Props = SquareIconProps & {className?: string};

export default function SquareUserIcon ({ onClick, className, size, testId, isHoverable, bgColor = 'dark' }: Props) {
  return (
    <SquareWrapper onClick={onClick} size={size} isHoverable={isHoverable} bgColor={bgColor} testId={testId}>
      <UserIcon className={className}/>
    </SquareWrapper>
  );
}