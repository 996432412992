import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import MenuButton from 'components/header/exp/menu-button';
import ThemedIcon from 'components/themed-icon';
import styles from './style.module.scss';
import { headerIds, testIds } from 'constants/test-constants';
import { useThemeContext } from 'contexts';

const CommercialHeader = () => {
  const { theme } = useThemeContext();
  const [menuDropdownIsActive, setMenuDropdownIsActive] = useState(false);
  const [Dropdown, setDropdown] = useState<any>();

  const toggleMenuDropdown = () => {
    if (!Dropdown) {
      setDropdown(dynamic(import('components/dropdown')));
    }
    setMenuDropdownIsActive(prev => !prev);
  };

  return (
    <div className={styles.component} data-testid={headerIds.header}>
      <div className={buildClassName(styles['layout-container'])}>
        <div className={buildClassName(styles['logo-wrapper'])}>
          <Link href="/" as="/" className={styles.logo} rel="noreferrer"><ThemedIcon /></Link>
        </div>
        <div className={styles.navigation}>
          {theme.menuLinks.map((link, index) => (
            <Link key={index} href={link.link} className={styles['menu-item']}>{link.label}</Link>
          ))}
        </div>
        <MenuButton className={styles.menu} testId={testIds.menuButton} onClick={toggleMenuDropdown} closeMenu={() => setMenuDropdownIsActive(false)}>
          <>
            <div className={styles['bar-container']} id="menu">
              <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
              <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
              <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
            </div>
            {menuDropdownIsActive && Dropdown && <Dropdown items={theme.menuLinks} />}
          </>
        </MenuButton>
        <Link href='/advisory-center' className={styles['advisory-button']}>
          Advisor Sign-In
        </Link>
      </div>
    </div>
  );
};

export default CommercialHeader;
