import { SavedSearchApiManager } from './api';
import { ThemeNames } from 'types/themes';
import type { SavedSearch } from './types';
export type { SavedSearch as default };

export async function getSavedSearches(pageSize: number, pageNumber?: number, source: ThemeNames = ThemeNames.ZOOCASA) {
  return SavedSearchApiManager.get({ page: { size: pageSize, number: pageNumber }, source });
}

export async function createSavedSearch(search: Partial<SavedSearch>) {
  return SavedSearchApiManager.create(search);
}

export async function deleteSavedSearch(id: string) {
  return SavedSearchApiManager.delete(id);
}

export async function updateSavedSearch(search: Partial<SavedSearch>) {
  return SavedSearchApiManager.update(search);
} 