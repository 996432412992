import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const HollowHeartIcon = ({ className, onClick }: Props) => {
  return (
    <svg width="25px" height="23px" viewBox="0 0 25 23" version="1.1" className={className} xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="iOSTablet-NoneSelected" transform="translate(-357.000000, -16.000000)" stroke="#191B1E" strokeWidth="2">
          <g id="Group-4">
            <path d="M374.94686,17.0004565 C378.269439,17.0004565 380.662359,19.8213043 380.963199,23.1831304 C380.963199,23.1831304 381.125579,24.0181087 380.768159,25.5205217 C380.281479,27.5666522 379.137459,29.3845217 377.595079,30.7714348 L369.431,38 L361.404921,30.7709783 C359.862081,29.3840652 358.718521,27.5661957 358.231841,25.5200652 C357.874421,24.0176522 358.036801,23.1826739 358.036801,23.1826739 C358.337641,19.8208478 360.730561,17 364.05314,17 C366.52012,17 368.50272,18.5261522 369.431,20.7096957 C370.35974,18.5261522 372.47988,17.0004565 374.94686,17.0004565 Z" id="Mask"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HollowHeartIcon;
