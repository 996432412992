import React, { ReactElement } from 'react';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import Button from 'components/control/button';

interface Props {
  onClick: () => void;
  closeMenu: () => void;
  className?: string;
  children: ReactElement;
  testId?: string;
}

export default function MenuButton({ onClick, closeMenu, className, children, testId }: Props) {
  const menuElement = useOutsideClickHandler<HTMLButtonElement>(closeMenu);

  return <Button label={children} className={className} onClick={onClick} ref={menuElement} testId={testId}/>;
}
