import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'contexts/user';
import { ModalContext } from 'contexts/modal';
import Button from 'components/control/button';
import Link from 'components/link';
import MapIcon from 'components/icon/map-icon';
import NewsIcon from 'components/icon/news-icon';
import TagIcon from 'components/icon/tag-icon';
import HollowHeartIcon from 'components/icon/hollow-heart-icon';
import { useRouter } from 'next/router';
import { buildClassName } from 'utils/build-class-name';
import getSearchPageParameters from 'utils/get-search-page-redirect-url';
import { formatQueryIntoURLQueryParams } from 'utils/listing-query-helper';
import { IPreferences, PreferencesContext } from 'contexts/preferences';
import styles from './style.module.scss';
import { trackEvent } from 'utils/google-tag-manager';
import { GTM_CLICK_MOBILE_BOT_NAV_ON_MAP_MY_FAV } from 'constants/events';
import { ThemeNames } from 'types/themes';
import { useThemeContext } from 'contexts';
import useDebounce from 'hooks/use-debounce';
import { AREA_LISTINGS_ROUTE, getRouteNameFromPath } from 'components/dynamic-page/route-matchers';

import type { IUserContext } from 'contexts/user';
import type { IModalContext } from 'contexts/modal';

const MobileBottomNav = ({ handleMapListToggleButtonClick }: {handleMapListToggleButtonClick?: () => void}) => {
  const { isAuthenticated } = useContext(UserContext) as IUserContext;
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { listingParams } = useContext(PreferencesContext) as IPreferences;
  const { themeName } = useThemeContext();
  const isZoocasaTenant = themeName === ThemeNames.ZOOCASA;
  const router = useRouter();
  const { asPath } = router;
  const isOnSoldRoute = asPath.includes('not-available-sold');
  const isOnSearchRoute = asPath.startsWith('/search') && !isOnSoldRoute;
  const onFavouritesRoute = asPath.startsWith('/account/saved/favourites');
  const isOnAreaPage = getRouteNameFromPath(router.asPath) === AREA_LISTINGS_ROUTE;

  const clickFavourites = () => {
    trackEvent(GTM_CLICK_MOBILE_BOT_NAV_ON_MAP_MY_FAV);
    if (isAuthenticated) {
      router.push('/account/saved/favourites');
    } else {
      openModal('login-registration');
    }
  };
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      setIsVisible(false); 
    } else {
      setIsVisible(true);  
    }

    setLastScrollY(window.scrollY); 
  };
  const debouncedScroll = useDebounce(handleScroll, 50);
  
  useEffect(() => {
    if (isOnAreaPage || isOnSearchRoute) { //need a ref to target scroll position inside of scrollable element, on the list it's not window
      window.addEventListener('scroll', debouncedScroll, { passive: true });
      return () => window.removeEventListener('scroll', debouncedScroll);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnAreaPage, isOnSearchRoute]);

  const navigateToSearch = () => {
    router.push(`/search?${formatQueryIntoURLQueryParams(listingParams.filter)}&sort=${listingParams.sort}`);
    sessionStorage.setItem('scroll-position', JSON.stringify({ position: window.scrollY, url: window.location.pathname }));
  };

  return (
    <div className={buildClassName(styles.component, isVisible ? styles['is-visible'] : styles['is-hidden'])}>
      {!(isOnAreaPage || isOnSearchRoute) ? null : <Button
        label='Map'
        className={buildClassName(styles['map-chip'], !isZoocasaTenant && styles['exp-theme'], isOnSearchRoute && styles.active)}
        Icon={MapIcon}
        onClick={isOnSearchRoute ? handleMapListToggleButtonClick : navigateToSearch}
      />}
      <Link href={getSearchPageParameters({ ...listingParams, filter: { ...listingParams?.filter, status: 'not-available-sold' }})} className={buildClassName(styles.item, isOnSoldRoute && styles.active)}>
        <>
          <TagIcon className={styles.icon} />
          Sold
        </>
      </Link>
      <div onClick={clickFavourites} className={buildClassName(styles.item, onFavouritesRoute && styles.active)}>
        <HollowHeartIcon className={buildClassName(styles.icon, styles['icon-heart'])} />
          Favourites
      </div>
      <Link href={getSearchPageParameters(listingParams)} className={buildClassName(styles.item, isOnSearchRoute && styles.active)}>
        <>
          <MapIcon className={styles.icon} />
          Map
        </>
      </Link>
      {isZoocasaTenant && 
        <a href="https://www.zoocasa.com/blog/" className={styles.item}>
          <NewsIcon className={styles.icon} />
          Articles
        </a>
      }
    </div>
  );
};

export default MobileBottomNav;
