import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const TagIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M30.369.351a1.2 1.2 0 00-.85-.351H18.393a1.2 1.2 0 00-.849.352L.351 17.544a1.2 1.2 0 000 1.697L11.48 30.369a1.2 1.2 0 001.697 0l17.192-17.192a1.2 1.2 0 00.352-.849V1.2a1.2 1.2 0 00-.351-.849zm-2.05 11.48L12.329 27.823l-9.431-9.43L18.889 2.4h9.43v9.431z"></path>
          <path d="M25.044 5.676a3.722 3.722 0 00-5.258 0 3.722 3.722 0 000 5.258 3.722 3.722 0 005.258 0 3.722 3.722 0 000-5.258zm-1.697 3.56a1.32 1.32 0 01-1.864 0 1.32 1.32 0 010-1.863 1.32 1.32 0 011.864 0 1.32 1.32 0 010 1.864z"></path>
        </g>
      </g>
    </svg>
  );
};

export default TagIcon;
