import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ExprealtyCaIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 22" className={className} style={{ height: '40px' }} onClick={onClick}>
      <g clipPath="url(#clip0_24653_2890)">
        <path d="M3.77675 10.6282C3.87917 11.5547 4.23765 12.2738 4.85218 12.7885C5.46671 13.3032 6.20854 13.5605 7.07912 13.5605C7.84728 13.5605 8.49108 13.4017 9.01196 13.0841C9.53139 12.7679 9.98789 12.3679 10.3815 11.8885L12.5821 13.5605C11.8651 14.4532 11.0633 15.087 10.1766 15.4635C9.2885 15.8399 8.35939 16.0297 7.38638 16.0297C6.46459 16.0297 5.59401 15.8752 4.7761 15.5664C3.95672 15.2576 3.24855 14.8208 2.65158 14.2547C2.05461 13.6885 1.58054 13.0076 1.23084 12.2105C0.881146 11.4135 0.705566 10.5252 0.705566 9.5473C0.705566 8.56936 0.879683 7.68259 1.23084 6.88406C1.58054 6.08701 2.05314 5.40465 2.65158 4.83848C3.24855 4.2723 3.95672 3.83554 4.7761 3.52671C5.59547 3.21789 6.46605 3.06348 7.38638 3.06348C8.23941 3.06348 9.01928 3.21348 9.72745 3.51348C10.4356 3.81348 11.0414 4.24583 11.5447 4.81201C12.048 5.37818 12.4402 6.07671 12.7226 6.90906C13.0035 7.74142 13.1454 8.6973 13.1454 9.77671V10.6252H3.77675V10.6282ZM10.0728 8.31348C10.0552 7.38701 9.77427 6.66789 9.22851 6.15318C8.68275 5.63848 7.92337 5.38112 6.95036 5.38112C6.02857 5.38112 5.29991 5.6473 4.76146 6.17818C4.22448 6.71054 3.89527 7.4223 3.77675 8.31348H10.0728Z" fill="white"/>
        <path d="M28.0347 3.37328H30.9522V5.22475H31.0034C31.4468 4.45269 32.0437 3.89975 32.7944 3.56592C33.545 3.2321 34.3555 3.06445 35.2261 3.06445C36.164 3.06445 37.0083 3.2321 37.7603 3.56592C38.5109 3.89975 39.1503 4.36004 39.68 4.94239C40.2082 5.52622 40.6091 6.21151 40.8827 6.99975C41.1549 7.78945 41.2924 8.63798 41.2924 9.54681C41.2924 10.4556 41.1476 11.3056 40.8579 12.0939C40.5682 12.8836 40.1629 13.5689 39.642 14.1512C39.1211 14.735 38.4948 15.1939 37.7603 15.5277C37.0273 15.8615 36.2255 16.0292 35.3549 16.0292C34.7916 16.0292 34.2839 15.9689 33.8317 15.8497C33.3796 15.7292 32.9831 15.5762 32.6422 15.3865C32.3013 15.1983 32.0101 14.9968 31.7716 14.7821C31.5331 14.5674 31.337 14.3571 31.1834 14.1512H31.1073V21.585H28.0361V3.37328H28.0347ZM38.2212 9.54681C38.2212 9.06739 38.1408 8.60416 37.9783 8.15857C37.8159 7.71298 37.5774 7.31886 37.2614 6.97475C36.9454 6.6321 36.5664 6.3571 36.1231 6.15122C35.6797 5.94534 35.1764 5.84239 34.6131 5.84239C34.0497 5.84239 33.5464 5.94534 33.1031 6.15122C32.6597 6.3571 32.2793 6.6321 31.9647 6.97475C31.6487 7.31886 31.4102 7.71298 31.2478 8.15857C31.0854 8.60416 31.0049 9.06739 31.0049 9.54681C31.0049 10.0262 31.0854 10.4909 31.2478 10.935C31.4102 11.3821 31.6487 11.7762 31.9647 12.1189C32.2808 12.4615 32.6597 12.7365 33.1031 12.9424C33.5464 13.1483 34.0497 13.2512 34.6131 13.2512C35.1764 13.2512 35.6797 13.1483 36.1231 12.9424C36.5664 12.7365 36.9454 12.463 37.2614 12.1189C37.5774 11.7762 37.8159 11.3821 37.9783 10.935C38.1408 10.4895 38.2212 10.0262 38.2212 9.54681Z" fill="white"/>
        <path d="M21.8131 9.5479L26.4982 15.7214H22.7627L19.9417 12.0067L17.128 15.7214H13.3911L25.0087 0.411133H28.7441L21.8131 9.5479Z" fill="white"/>
        <path d="M17.128 3.375H13.3911L17.1719 8.36323L19.0375 5.89853L17.128 3.375Z" fill="white"/>
        <path d="M3.54346 18.034C3.54346 17.987 3.5815 17.9443 3.63271 17.9443H4.97882C5.54361 17.9443 6.00597 18.3987 6.00597 18.962C6.00597 19.3973 5.71919 19.7517 5.3095 19.9179L5.95476 21.1193C5.98841 21.1811 5.95476 21.2561 5.87428 21.2561H5.37973C5.3373 21.2561 5.31389 21.2326 5.30365 21.2135L4.67741 19.959H4.15506V21.1664C4.15506 21.2135 4.11263 21.2561 4.06581 21.2561H3.63271C3.5815 21.2561 3.54346 21.2135 3.54346 21.1664V18.034ZM4.92761 19.4532C5.17781 19.4532 5.39436 19.2355 5.39436 18.9708C5.39436 18.7193 5.17781 18.512 4.92761 18.512H4.16091V19.4532H4.92761Z" fill="white"/>
        <path d="M7.99658 18.034C7.99658 17.987 8.03462 17.9443 8.08583 17.9443H10.0026C10.0538 17.9443 10.0918 17.987 10.0918 18.034V18.4223C10.0918 18.4693 10.0538 18.512 10.0026 18.512H8.60819V19.2885H9.7714C9.81822 19.2885 9.86066 19.3311 9.86066 19.3782V19.7708C9.86066 19.8223 9.81822 19.8605 9.7714 19.8605H8.60819V20.6885H10.0026C10.0538 20.6885 10.0918 20.7311 10.0918 20.7782V21.1664C10.0918 21.2135 10.0538 21.2561 10.0026 21.2561H8.08583C8.03462 21.2561 7.99658 21.2135 7.99658 21.1664V18.034Z" fill="white"/>
        <path d="M11.37 21.1342L12.8346 17.9489C12.8493 17.921 12.8727 17.8975 12.9151 17.8975H12.9619C13.0087 17.8975 13.0278 17.921 13.0424 17.9489L14.4924 21.1342C14.5202 21.196 14.4836 21.2578 14.4119 21.2578H14.0022C13.932 21.2578 13.8983 21.2298 13.8662 21.1636L13.635 20.6519H12.2274L11.9962 21.1636C11.9772 21.2107 11.9348 21.2578 11.8601 21.2578H11.4505C11.3802 21.2578 11.3422 21.196 11.37 21.1342ZM13.4038 20.1269L12.9327 19.0857H12.918L12.4571 20.1269H13.4038Z" fill="white"/>
        <path d="M15.8628 18.034C15.8628 17.987 15.9008 17.9443 15.952 17.9443H16.3851C16.432 17.9443 16.4744 17.987 16.4744 18.034V20.6885H17.6757C17.7269 20.6885 17.7649 20.7311 17.7649 20.7782V21.1664C17.7649 21.2135 17.7269 21.2561 17.6757 21.2561H15.952C15.9008 21.2561 15.8628 21.2135 15.8628 21.1664V18.034Z" fill="white"/>
        <path d="M19.8116 18.512H19.0917C19.039 18.512 19.0024 18.4693 19.0024 18.4223V18.034C19.0024 17.987 19.0405 17.9443 19.0917 17.9443H21.1489C21.2001 17.9443 21.2382 17.987 21.2382 18.034V18.4223C21.2382 18.4693 21.2001 18.512 21.1489 18.512H20.429V21.1664C20.429 21.2135 20.3866 21.2561 20.3398 21.2561H19.9023C19.8555 21.2561 19.813 21.2135 19.813 21.1664V18.512H19.8116Z" fill="white"/>
        <path d="M23.8355 19.662L22.7615 18.0811C22.7235 18.0193 22.7615 17.9443 22.8362 17.9443H23.3161C23.3541 17.9443 23.3775 17.9679 23.3922 17.987L24.1457 19.0752L24.8992 17.987C24.9139 17.9679 24.9373 17.9443 24.9753 17.9443H25.4596C25.5357 17.9443 25.5723 18.0208 25.5357 18.0811L24.4486 19.6576V21.1679C24.4486 21.2149 24.4061 21.2576 24.3593 21.2576H23.9262C23.875 21.2576 23.837 21.2149 23.837 21.1679V19.6635L23.8355 19.662Z" fill="white"/>
        <path d="M40.3466 0.411133C39.8257 0.411133 39.4028 0.836133 39.4028 1.35966C39.4028 1.88319 39.8257 2.30819 40.3466 2.30819C40.8675 2.30819 41.2903 1.88319 41.2903 1.35966C41.2903 0.836133 40.8675 0.411133 40.3466 0.411133ZM40.3466 2.13466C39.9208 2.13466 39.5755 1.7876 39.5755 1.35966C39.5755 0.931721 39.9208 0.584662 40.3466 0.584662C40.7724 0.584662 41.1177 0.931721 41.1177 1.35966C41.1177 1.7876 40.7724 2.13466 40.3466 2.13466Z" fill="white"/>
        <path d="M40.3677 1.48685H40.2053V1.85155H40.0005V0.854492H40.3692C40.4863 0.854492 40.577 0.880963 40.6399 0.933904C40.7028 0.986845 40.735 1.06037 40.735 1.15596C40.735 1.22361 40.7204 1.28096 40.6911 1.32655C40.6618 1.37214 40.6179 1.4089 40.558 1.43537L40.773 1.84273V1.85302H40.5536L40.3677 1.48832V1.48685ZM40.2053 1.32067H40.3707C40.4219 1.32067 40.4614 1.30743 40.4906 1.28096C40.5199 1.25449 40.5331 1.2192 40.5331 1.17214C40.5331 1.12508 40.5199 1.08832 40.4936 1.06185C40.4672 1.03537 40.4263 1.02214 40.3707 1.02214H40.2068V1.32214L40.2053 1.32067Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_24653_2890">
          <rect width="40.5882" height="21.1765" fill="white" transform="translate(0.705566 0.411133)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExprealtyCaIcon;
