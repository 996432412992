import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const NewsIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero" transform="translate(-260 -676)">
          <g transform="translate(260 676)">
            <path d="M20.866 4.536h-2.722V.907c0-.5-.406-.907-.907-.907H2.722A2.724 2.724 0 000 2.722v16.33a2.724 2.724 0 002.722 2.72h16.33a2.724 2.724 0 002.72-2.72V5.442c0-.5-.406-.907-.906-.907zM2.722 19.958a.908.908 0 01-.908-.907V2.721c0-.5.407-.907.908-.907H16.33v17.237c0 .319.058.623.158.907H2.722zm17.236-.907a.908.908 0 01-1.814 0v-12.7h1.814v12.7z"></path>
            <path d="M13.608 4.536H4.536a.908.908 0 000 1.814h9.072a.908.908 0 000-1.814zM11.794 9.98H4.536a.908.908 0 000 1.814h7.258a.908.908 0 000-1.815zM10.886 12.7h-6.35a.908.908 0 000 1.815h6.35a.908.908 0 000-1.814zM9.98 15.422H4.535a.908.908 0 000 1.815h5.443a.908.908 0 000-1.815z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NewsIcon;
