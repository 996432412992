import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const MenuIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="22" viewBox="0 0 31 22" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M29.52 9.6H1.2a1.2 1.2 0 100 2.4h28.32a1.2 1.2 0 100-2.4zM29.52 0H1.2a1.2 1.2 0 100 2.4h28.32a1.2 1.2 0 100-2.4zM29.52 19.2H1.2a1.2 1.2 0 100 2.4h28.32a1.2 1.2 0 100-2.4z"></path>
        </g>
      </g>
    </svg>
  );
};

export default MenuIcon;
